.pagination li a {
    position: relative;
    display: block;
    padding: 1px 1px;
    margin-left: -1px;
    margin-left: 5px !important;
    border-radius: 43px;
    line-height: 1.25;
    color: rgb(0, 82, 158);
    background-color: #fff;
    border: 1px solid rgb(0, 82, 158);
    font-size: 8px;
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 2.7;
}

.pagination li button {
    position: relative;
    display: block;
    padding: 1px 1px;
    margin-left: -1px;
    margin-left: 5px !important;
    border-radius: 43px;
    line-height: 1.25;
    color: rgb(0, 82, 158);
    background-color: #fff;
    border: 1px solid rgb(0, 82, 158);
    font-size: 8px;
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 2.7;
}