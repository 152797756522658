@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);
.App {
  text-align: center;
}

/* sidenav and headernav css  */
.side-nav.fixed {
  left: 0;
  transform: translateX(0);
  position: fixed;
  box-shadow: 1px 0 15px rgba(73, 72, 72, 0.41);
  z-index: 10000;
}
.white-skin .side-nav .collapsible li .collapsible-header, .white-skin .side-nav .collapsible li a:not(.collapsible-header), .white-skin .side-nav .collapsible li a:not(.collapsible-header):hover, .side-nav .collapsible a{
  /* color: #00529e !important;
  font-weight: bold;
  cursor: pointer; */
}
.side-nav .collapsible a {
  font-weight: 300;
  color: #fff;
}
.side-nav .collapsible li a:hover {
 background-color: rgba(0, 0, 0, 0.15) !important;
}
.side-nav .collapsible > li a{
  font-size: 15px;
  padding-left: 5px;
}
.side-nav .collapsible > li li a {
  font-size:14px;
  font-weight:500 !important;
  padding-left: 18px;
 }
 
 .side-nav {
  padding-top: 0px;
   padding-bottom: 100px;
 }
.white-skin .navbar {
  background-color: #283a4c;
  color: #424242;
  z-index: 1000;
  position: sticky;
  height: 62px;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 1.5rem !important;
  /* right: 0; */
}
.side-nav .collapsible .fas{
  font-size: 0.8rem;
  margin-right: 13px;
  width: 16px;
}
.side-nav .collapsible ul {
  padding: 0;
  list-style-type: none;
  padding-left: 28px;
}
.side-nav .collapsible {
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding-left: 1rem;
}
.white-skin .side-nav {
  background-color: #f4f3f3;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important; */
}
.sidemenu_divline {
  border: 1px solid #222F4D;
}

.navbar .dropdown-menu {
  position: absolute !important;
  margin-top: 0;
  right: 0 !important;
  left: auto !important;
}
.dropdown-item{
  font-size: 13px;
}

.navbar-dark .navbar-brand {
  color: #fff;
  font-size: 1.5rem;
}
.left-sidebar i{
  width: 10px !important;
}
.logo-sn a {
  color: #74798b !important;
  font-size: 41px !important;
  font-weight: bold;
  text-shadow: 2px 3px #cbcbcb;
}
.drop_menu{
  min-width: 81px !important;
  height: auto;
}
.sidebar-main-div{
  /* background: rgb(241, 243, 246) !important; */
  color: #fff !important;
  transition: all 0.3s;
  width: 22rem;
  position: relative;
  height: 100vh !important;
  padding-top: 0px;
}
.sidebar-main-div > div{
  /* width: 15rem; */
}
.scroll_nav > div, .scroll_nav{
  width: 6rem;
}
.scroll_nav > div, .scroll_nav a{
  white-space: nowrap;
}
.scroll_nav > div, .scroll_nav a.break-word{
  overflow-wrap: break-word;
  white-space: normal;
}
.scroll_nav:hover li .show {
  /* display: block; */
 }
 .scroll_nav li .show {
   display: none;
 }
.scroll_nav:hover + main {
  margin-left: 3rem;
}
.scroll_nav:hover .logo-sn a{
  visibility: visible;
}
.scroll_nav:hover .logo-sn a{
  visibility: visible;
}
.scroll_nav .rotate-icon{
  visibility: hidden;
}
.scroll_nav i{
  display: none
}
/* .scroll_nav:hover i{
  display: block;
} */
.scroll_nav:hover .rotate-icon{
  visibility: visible;
}
  
.break-word{
  overflow-wrap: break-word;
}
/* .scroll_nav:hover > div{
width:15rem ;
} */
.slide-arrow:hover i{
color: blue !important;
cursor: pointer;
}
.logo-sn a{
width: 15rem;
}
.slide-arrow{
position: absolute;
    left: 5px;
}
/* .slide-arrow{
  position: fixed;
  left: 0px;
  z-index: 100000;
  top: 26px;
  width: 34px !important;
  height: 34px;
  font-size: 23px;
  border-top-right-radius: 5px;
  line-height: 1;
  text-align: center;
  color: #394263;
  padding-left: 8px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
} */
.slide-arrow i{
    padding: 8px 8px 6px 8px;
    border-radius: 100%;
}
.slide-arrow i:hover{
  color: #fff !important;
  background: #00529e;
}
/* .slide-arrow:hover{
color:#2360a5;
} */
/* end sidenav css  */

/* model css */
.modal{
  z-index: 100000 !important;
}
.modal-backdrop{
  z-index: 100000 !important;
}
/* end model css  */



/* start dashboard card css */
 .card-text{
  font-size: 1.25rem !important;
 }
 .dashboard .card:hover{
  cursor: pointer;
}
.card-div{
  min-height: 150px;
}
.card{
  border: 0px !important;
}
.card.card-cascade.wider.reverse .card-body.card-body-cascade{
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18), 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  margin-top: 0px;
}

.card-title {
  font-weight: 600!important;
  font-size: 1.25rem;
  }
/* dashboard card css */

/* login page css */
.md-form{
  margin-top: 0.2rem;
  color: #fff;
  margin-bottom: 0.66rem;
}
.md-form input[type=text]:not(.browser-default){
  border-bottom: 1px solid #ffffff;
  padding-left: 5px;
}
.login-page .intro-2 {
  height: 100vh;
}
.login-page .md-form {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.form-header {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  text-align: center;
  margin-top: -3.13rem;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 0.125rem;
}
.purple-gradient {
  background: #394263;
}
.login-page .card-body {
  background: #fff;
}
.login-page .white-text {
  color: #a2a2a2 !important;
}
.login-page .md-form label, .login-page .md-form .form-control {
  color: #141414;
  font-weight: 400;
}
.login-btn{
  background:#00529e !important;
  color: #fff !important;
}
.login_icon{
  width: 138px;
  margin-left: 126px;
  padding-bottom: 10px;
  padding-top: 11px;
}
/* end login page css */

.cascading-admin-card .admin-up .data {
  word-break: break-word;
  width: 74%;
}

.title_bar{
  margin-left: 0px;
  background: #fff;
  margin-right: 0px;
  margin-bottom: 21px;
  height: 47px;
}
.white-skin .btn-default{
  background-color: #ffffff !important;
  color: #756b6b;
}
.content_wbg{
  background: #fff;
  padding: 20px;
  padding-bottom: 15px;
  border: 1px solid #dbd8d8;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
}

/* form related css */
.md-form input:not([type]):focus:not([readonly]) + label,
.md-form input[type=text]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=password]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=email]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=url]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=time]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=date]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=number]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=search]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type=search-md]:focus:not([readonly]) + label,
.md-form textarea.md-textarea:focus:not([readonly]) + label {
  color: #1258cd;
}
input[type="file"] {
  cursor:inherit;
  display:block;
  border:none;
  width: 100%;
  border-bottom: 1px solid #cdcdcd;
  height: 32px;
  margin-top: 7px;
  color: #1258cd;
}
.form_title{
  background: #eceaea;
  line-height: 3;
  border-radius: 2px;
  padding-left: 21px;
}
.select_height{  /* select box height*/
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
  height: 39.5px !important;
  border-radius: 0px !important;
  padding-left: 5px !important;
}
.select_box{
  width: 35% !important;
  margin-left: 15px;
}
.md-form input[type=text]:not(.browser-default) {
  border-bottom: 1px solid #cdcdcd;
}
.select_height1{
  border-bottom: 1px solid #ced4da !important;
  min-height: 45px !important;
  max-height: 100px;
  border-radius: 0px !important;
}
.select_btn{
    border-radius: 20px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 4px;
    padding-bottom: 5px;
}
.btn-rounded{
  border-radius:100% !important;
  height:43px;
}
.mtb_30{
  margin-top:30px;
  margin-bottom:30px;
  border-bottom:1px solid #e6dfdf;
}

.btn-floating.btn-lg i{
    line-height: 49.1px;
}
.cascading-admin-card .admin-up .data p {
    color: #221f1f;
    font-size: 16px;
}
.input_label{
  white-space: nowrap;
  margin-top: 8px;
}
/* end form related css */

.cur_p{
  cursor:pointer;
  margin-right:10px;
  margin-left:10px;
}
.double-nav .breadcrumb-dn p {
  margin: 0;
  padding-top: 0;
  padding-left: 0rem;
}
.float_right{
  float: right;
}
.btn-rounded {
  border-radius: 100% !important;
  height: 40px;
}
.error-msg p{
margin-left: 40px;
}

/* calendar css */
.cal_label{
  transition: transform .2s ease-out, color .2s ease-out;
  transform-origin: 0% 100%;
  transform: translateY(12px);
  cursor: text;
  margin-top: 0px;
  display: block;
  /* width: 70px; */
  float: left;
  padding-left: 4px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.cal_margin{
  margin-left: 17px;
  margin-top: 5px;
  padding-left: 10px;
  width: 126px;
  display: block;
  min-width: 50%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.5;
  color: #00529e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* end calendar css */
select.browser-default {
  display: block !important;
}
.reset_mar{
  margin-top: 7px;
}
.sn-bg-4 {
   background-image: none;
}
.menuicon_img{
  width: 22px;
  margin-right: 10px;
  margin-left: 5px;
}
.charge_table td:last-child{
  white-space:nowrap;
}
small.text-muted{
  padding-left: 5px;
}
.text-muted{
  color: #319bfd !important;
}
.narrower{
  position: relative;
}
/* cal css for datetime picker */
.cal_disable input{
  color: #757575;
}
/* end date/time picker css */

/* media query */
@media (max-width:1440px){
    .fixed-sn .double-nav, .fixed-sn main, .fixed-sn footer {
        padding-left:16rem;
    }
}
.md-form > input[type]:-webkit-autofill:not(.browser-default):not([type="search"]) + label{
  transform: translateY(-7px);
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.md-form .form-control{
  margin: 0 0 0.8rem 0;
}

/* css image-overlay */
.content {
  position: relative;
  margin: auto;
}
.content .image-overlay {
  background: rgba(250, 250, 250, 0.2);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
}
.content:hover .image-overlay{
  opacity: 1;
}
.content-image{
  width: 100%;
}
.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out 0s;
}
.content:hover .content-details{
  top: 50%;
  left: 50%;
  opacity: 1;
}
.content-details h3{
  color: #fffbfb;
  font-weight: 500;
  margin-bottom: -1.5em;
  text-transform: none;
  font-size: 18px;
}
.content-details h3 span{
  padding: 10px 20px;
  background: #8e8e8e;
  border-radius: 3px;
  cursor: pointer;
}
.content-details p{
  color: #fff;
  font-size: 0.8em;
}
.fadeIn-top{
  top: 20%;
}

/* ===================== ==================== page layout css======================= ======================== */

main{
  margin-bottom: 20px;
}
.add_new i{
  margin-top: 7px;
}
.page-outerdiv{
  border: 1px solid #dcd7d7;
  padding: 20px;
  background: #fff;
  min-height: 75vh;
  position: relative;
  padding-bottom: 40px;
  box-shadow: 0 0px 9px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-radius: 15px;
}
/* menu bar css */
.menubar button{
  color: #00529e;
  border-color: #f2f9ff;
  background-color: transparent;
  border: none;
  font-size: 13px;
  margin-right: 1px;
  padding: 1px 14px;
  margin-top: 3px;
  border-right: 2px solid #acacac;
}
.custom_back_button {
  color: #00529e;
  border-color: #f2f9ff;
  background-color: transparent;
  border: none;
  font-size: 13px;
  margin-right: 1px;
  padding: 1px 14px;
  margin-top: 3px;
  border-right: 2px solid #acacac;
}

.menubar  button a  {
  padding-right: 5px;
  float: left;
  font-size: 13px;
  padding-top: 2px;
}
.menubar button i{
  padding-right: 5px;
  font-size: 13px;
}
.menubar button a{
  color: inherit;
  line-height: inherit;
  display: table;
}
.menubar div > .border_bottom{
  padding-bottom: 7px;
  background: #e7ecf2;
  padding-top: 5px;
}
/* end menu bar css */
.form_select{
  display: block !important;
  background: #fdfdfd !important;
  height: 30px !important;
  padding: .1rem .75rem !important;
  border-radius: 0px !important;
}
.custom_select{
  margin-left: 16px;
  min-width: 30px;
} 
.page-table{
  margin-top: 15px;
}
.page-table a{
  text-decoration: underline;
}
.sel_w{
  width:170px;
}
.sel_lbl{
  padding-top:4px;
  
}
.mr-10{
  margin-right: 10px;
}
.submenu_icon{
  padding-right: 10px;
  color: #2360a5;
  font-size: 19px;
  float: left;
  margin-top: 10px;
}
.border_bottom{
  border: 1px solid #cfdded;
}
.border_bottom button:hover{
  color: blue;
}
.form-title{
  padding-bottom: 8px;
  display: block;
  font-weight: bold;
  padding-top: 12px;
  margin-top: 12px;
  font-size: 15px;
  border-bottom: 1px solid #eae9e9;
}
.form-title button{
  margin-top: 0px !important;
}
.md-form label.active {
  transform: translateY(-14px) scale(0.8);
  padding-left: 0px;
}
.needs-validation > button{
  float: right;
}
.left-sidebar i.rotate-icon{
  margin-right: 14px !important;
  width: 16px;
}
.root{
  display:flex;
  align-items:stretch;
}

main{
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  transition: all 0.3s;
  margin: 0px !important;
  margin-bottom: 20px;
  padding-top: 0px !important;
  margin-right: 15px !important;
}
.d-flex{
    float: right !important;
    width: 100%;
    vertical-align:top;
    padding-bottom:0px;
}
.error_msg {
  margin-left: 6px;
  display: block;
  font-size: 13px;
  margin-top: -10px;
}
.btn.btn-sm {
  padding: 0.5rem 0.7rem;
  font-size: 0.64rem;
}

.model_top{
  margin-top: 12% !important;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: transparent !important;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 0.7 !important;
}
/* .scroll_nav .logo-sn a, .scroll_nav .search-form input, .scroll_nav .fa-angle-down {
  visibility: visible;
} */
.v-hiiden{
  visibility: hidden;
}
.md-form.input-group .input-group-text.md-addon {
  font-weight:normal;
  background-color: transparent;
  border: none;
}
.download_input{
  padding-left: 22px !important;
  padding-right: 22px !important;
  border-radius: 3px !important;
  border: none;
  padding: 10px;

  border: 1px solid #ced4da;
  background: #00529e;
  color: #fff;
}
.excel_visi{
  display: none;
}
.loader_img{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
    z-index: 1000000;
    text-align: center;
    vertical-align: middle;
    padding: 12%;
}
/* .custom-control-label {
  color: #757575;
} */

.error_msg1 {
  margin-left: 40px;
  display: block;
  font-size: 14px;
  margin-top: -10px;
}
.error_Msg {
  display: block;
  font-size: 14px;
  margin-top: -10px;
}
.anchor_tag:hover{
  color: blue !important;
  text-decoration:underline;
  cursor:pointer !important;
} 

.custom-control-label::after{
  cursor: pointer;
}
.modal-header .close {
  display:none;
}


/* start charge point selection model */
.chargepoint_listdiv{
  max-height: 160px;
}
.chargepoint_span{
  display: block;
  float: left;
  padding: 2px 10px;
  border: 1px solid #dad2d2;
  border-radius: 32px;
  background: #00529E;
  font-size: 13px;
  margin-right: 10px;
  color: #f9eeee;
  margin-bottom: 5px;
}
.chargepoint_list{
     /* height: 30px; */
     border: 1px solid #e4dddd;
     width: 100%;
     padding: 5px;
     margin-top: 10px;
}
.chargepoint_listdiv{
  max-height: 245px;
  overflow-y: auto;
  margin-top: 11px;
}
/* new css */
  .chargepoint_listdiv ul{
    padding-left: 0px;
    margin-bottom: 5px;
  }
  .chargepoint_listdiv ul li{
    list-style-type: none;
    display: inline-block;
    min-width: 33% !important;
    margin-bottom: 5px;
    margin-top: 5px;
    min-width: 103px;
    max-width: 116px;
    float: left;
    width: auto !important;
    white-space: nowrap;
  }
  .chargepoint_listdiv ul li .custom-checkbox{
    float: left;
  }
  .chargepoint_listdiv ul li .custom-radio{
    float: left;
  }
/* end new css */
.chargepoint_listdiv th, .chargepoint_listdiv td{
  padding: 4px !important;
}
.chargepoint_listdiv .custom-control{
  min-height: 1.3rem;
}
/* for popup */
.custom_backdropclass{ 
  background: #00000087;
}
.chargepoint_listdiv_out{
    width: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    height: 74px;
    overflow-y: scroll;
    padding-right: 0.75rem;
    background-image: none;
    display: block ;
    color: #00529e;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid #dad7d7;
    width: 100%;
    word-break: break-word;
}
 .chargepoint_span i:hover{
  color: #093b8e;
  cursor: pointer;
 }
.freeuseredit .chargepoint_span i:hover{
  color: #fff !important;
 }
 .modal-title {
  margin-bottom: 0;
  color: rgb(120, 129, 149);
  font-size: 22px;
  line-height: 1.5;
}
.chargepoint_seldiv{
  max-height: 60px;
  overflow-y: auto;

}
.chargepoint_sel{
  float: right;
  width: 156px;
  height: 36px !important;
}
.form_margin{
  margin-top: 1rem !important;
}
.cal_disable{
  pointer-events:none;
}
.tag_cal{
  width:162px !important;
  cursor:pointer;
  margin-top: 0px;
  text-align: center;
}
.log_cal{
  width: 127px !important;
  border: none;
  border-bottom: 1px solid #ced4da;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  z-index: 100;
  background: transparent;
  padding-left: 33px;
  padding-bottom: 10px;
}

.cal_icon{
  font-size: 19px;
  margin-top: 7px !important;
  color: #211e1e8a;
  position: absolute;
  margin-right: 10px;
  padding-left: 25px;
  z-index: 0;
}

.log_title{
  width: 100%;
padding-left: 20px;
padding-bottom: 12px;
display: block;
border-bottom: 1px solid #ebe4e4;
margin-bottom: 14px;
}
.logs_row{
margin-bottom: 7px;
box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.16), 0 0px 1px 0 rgba(0, 0, 0, 0.12);
cursor: pointer;
}
.dropdown-item{
  margin-top: 3px;
}
/* end for charge point selection model */

 /*  css by neelima */
#righttext{
  margin-left: -27px;
}
.card-header {
  padding: 0.4rem 1.25rem !important;
}
.dated{
  margin-right: 0.5rem;
  margin-left: -0.8rem;
}
#chkbox-down{
  padding-top: 1rem;
}
#collbtn button{
  float: right;
}
 #increased i, #decreased i{
  font-weight:900;
  color: #00529e ;
}
#increased{
  margin-left: 1.2rem;
}

/* refresh button css */
#refreshbtn button{
  float: right;
  padding: 0.4rem 0.9rem 0.4rem 0.9rem;
  border: none;
  margin-top: -1rem;
  margin-bottom: -1rem;
}
#refreshbtn button:hover{
  cursor: pointer !important;
}
/* end refresh button css  */
/*  end css by neelima */

/* tab css */
.tab-content{
  border: 1px solid;
  border-color: #dee2e6;
  border-top: none;
}
.nav-tabs .nav-link {
  background: #e7e7e7 !important;
  padding-bottom: 9px;
}
.nav-tabs .nav-item{
  margin-right: 5px;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #00529e !important;
  background-color:#eef4fa !important;
  border-color: #b3c9de #b3c9de #fff !important;
}
.nav-tabs .nav-link.activetab{
  background-color: #00529E !important;
  color: #fff !important;
}
/* end tab css */

/* accordion css start */
.md-accordion .card-header:hover{
  background: #f9f9f9;
}
.accordion_angle i{
  float:right;
  line-height:1.6;  
  color: #00529e;
}   
.accordion > .card .card-header {
  background-color: #fff;
}
/* end accordion css start */
  .operation-text {
    font-weight: bold;
  }
  .trans_lable{
    font-size: 15px;
  }
  .icon_disable{
    cursor: default;
    opacity: 0.4;
  }
.custom_model_w{
  width: 620px !important;
  margin-top: 65px !important;
}
.tras_calicon{
  color: rgb(0, 82, 158);
    margin-right: 15px;
}
.cal_w{
  width: 130px !important;
  cursor: pointer;
  margin-top: 0px;
  text-align: center;
  padding-left: 0px !important;
  margin-left: 18px !important;
  padding-right: 0px !important;
}
.selection_charge_point_error{
  display: block;
  font-size: 13px;
  margin-top: -19px;
}
.mb_8{
margin-bottom:  0.8rem !important;
}
.nav_p{
  padding-left: 4rem !important;
  transition: all 0.2s;
}
.dashboard{
  min-height: 50%;
  position: relative;
  padding-bottom: 40px;
  margin-right: 15px !important;
  margin-left: 15px !important;
  margin-top: 23px !important;
}
.menubar button:first-child{
  padding-left: 0px !important;
}

@media only screen and (max-width: 1024px) {
  .dashboard{
  margin-left: 30px !important;
  }
  .page-outerdiv{
  margin-left: 45px !important;
  }
  .card-div .card{
  height: 135px;
  }
  }
  .cal_errormsg{
    float: left;
    width: 100%;
    margin-top: 3px;
  }
  .view-page-row {
    /* border-bottom: 1px solid #dcd7d7; */
    /* border-bottom: 1px solid #dcd7d7; */
    font-size: 14px;
    color: #00529e !important;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    /* border-bottom: 1px solid #e9ecef; */
    padding: 0.75rem 0.75rem 0.75rem 0rem;
}


.view-page-row label:first-child{
  width: 28%;
  text-align: left;
  font-weight: bold;
}
.view-page-row label{
  /* width: 48%; */
  text-align: left;
  padding-right: 14px;
  margin-bottom: 0px;
}
.view-bg-row{
  /* background: #eef4fa;  */
}
.charge_pointlogs{
  padding: 0.3rem 0.3rem 0.3rem 0rem;
}
.custom_confkey_w{
  max-width: 1010px !important;
  width: 1010px !important;
  }
  .check-list ul{
    padding-left: 0px;
    list-style: none;
   }
   .check-list ul li{
     display: inline-flex;
     width: 100%;
     padding-top: 5px;
     padding-bottom: 5px;
   }
   .pagination{
   justify-content: center;
   }
   .pagination li{
    z-index: 1;
    color: #fff;
    outline: 0;
    border: 0;
 
   }
   .pagination li a{
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
   }
   .pagination li.active a {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.2s linear;
    border-radius: 0.125rem;
    background-color: rgb(0, 82, 158);
    color: #fff;
}

.pagination li:first-child a {
  margin-left: 0;
  /* border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; */
}
.disable_radio {
  visibility: hidden;
  }
  .disable_radio + label{
    opacity: 0.7;
  }
  .check_cursor{
    pointer-events: none !important;
   }
   
.action-icon{
  cursor: pointer;
  opacity: 0.8;
 }
 .action-icon:hover{
   opacity: 1;
 }
 /* css for show entries */
.pull-left {
  float: left;
  padding-bottom: 5px;
}
/* css for fota(add build) */
.simple_file_upload, .simple_upload1
{
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
.simple_file_upload+span, .simple_upload1+span
   {
       border-radius: 4px;
       display: block;
       font-size: 14px;
       margin-top: 1em;
       padding: 5px;
       position: relative;
       width: 150px;
       background: linear-gradient(#f9f9f9cc 37%, #e5e5e5ba);
       border: 1px solid #999;
       border-radius: 1px;
       color: #00529e;
   }
   .simple_file_upload+span:after, .simple_upload1+span:after
   {
     bottom: -2px;
     content: attr(data-file);
     display: block;
     font-size: 15px;
     font-style: italic;
     left: 0px;
     padding-left: 153px;
     margin-top: 0.5em;
     position: absolute;
     width: 225%;
     text-overflow: ellipsis;
     overflow: hidden;
     border-bottom: 1px solid #cdcdcd;
     padding-bottom: 6px;
     position: absolute;
   }
   .simple_file_upload:focus+span,
   .simple_file_upload.focus+span,
   .simple_upload1:focus+span,
   .simple_upload1.focus+span
   {
     box-shadow: 0 0 6px 0 #0070b0;
   }
   .unarchive_icon svg{
    vertical-align: top;
    fill: #00529e;
    padding: 1px;
    margin-left: -3px;
    text-align: left;
    }
   /* css for angle in sidebar */
   .side-nav .fa-angle-down.rotate-icon {
    position: absolute;
    right: 0;
    top: 0.4rem !important;
    margin-right: 1.25rem;
}
.side-nav .collapsible .fas{
  font-size: 0.8rem;
  margin-right: 13px;
  width: 16px;
  float: right;
  padding-top: 7px;

}
.add-btn{
  font-size: 14px;
}
.word_break{
  word-break: break-all;
}
.text_lowercase{
  text-transform: lowercase;
}
.newdata .react-datepicker-popper{
  width: 328px !important;
}
.newdata1 .react-datepicker-popper{
right: 0px !important;
}
.fw-500{
  font-weight: 500;
}
.switch_acc{
  padding-left: 24px;
  padding-right: 32px;
  padding-top: 20px;
  font-family: 'Inter-Semibold';
  font-size: 16px;
}
.disabled-link{
  pointer-events: none;
  opacity: 0.4;
}
.chargepoint_label{
  right: 0;
    position: absolute;
    padding-top: 8px;
}
.charge_pl{
  padding-bottom: 0px;
  border-bottom: 0px !important;
  margin-top: 6px;
}
.w-50{
  width: 50%;
}
.w-40{
  width: 40% !important;
}
.m_26{
  margin-top: 2.6rem !important;
}


/* css for split dropdown under view page */
.white-skin .btn-primary.dropdown-toggle {
  background-color: #00529e !important;
}
.btn-mr{
  margin: 0.1rem;
}
.operation-right{
  position: absolute !important;
  float: right;
  right: 0;
  /* width: 100%; */
  max-width: 196px;
}
.drp-mt{
  /* margin-top:14rem !important; */
  max-height: 450px !important;
    overflow-y: scroll;

}
.dropdown-item-op{
/* white-space: pre-wrap !important; */
background-color: transparent !important;
border: 0;
}





.diagnostics_css table{
  border: 1px solid #dee2e6;
  margin-top: 10px;
  margin-bottom: 10px;
}
.diagnostics_css tbody tr:nth-of-type(odd) {
  background-color: #e7ecf2 !important;
}
.diagnostics_css tbody tr:nth-of-type(odd) {
  background-color: #e7ecf2 !important;
}
.diagnostics_css td{
  border: 1px solid #dee2e6;
  padding: 0.3rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-weight: 400;
}
.loader {
  background: #4141416b;
  height: 100%;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
}
.loader > img{
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  vertical-align: middle;
  top: 50%;
  transform: translateY(-50%);
}
.page_loader{
  background: #4141416b;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  position: fixed;
  z-index: 1000000;
  left: 285px;
  top: 62px;
  bottom: 0;
}
.operations_loader{
  background: #4141416b;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  bottom: 0;
}
.operations_loader > img{
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 47%;
  margin-left: 53%;
  vertical-align: middle;
  top: 43%;
  transform: translateY(-50%);
}
.page_loader > img{
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 47%;
  margin-left: 37%;
  vertical-align: middle;
  top: 43%;
  transform: translateY(-50%);
}
.w-70{
   width:70% !important;
}

/* css for backend connection icon */
.connection-pl{
  padding-left: 5rem!important;
  /* color:#29be29!important; */
  /* font-size: 1.1rem; */
 /* padding-top: 2rem!important; */
  color:#ef1111;
  display: block;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  top: centre!important;
}
.con-ti{
  font-size: 10px;
  font-weight: bold;
  color:#ef1111;
  padding-left: 6.3rem !important;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  top: -0.8rem;
  /* top: 10rem!important; */
  /* padding-bottom: 2rem!important; */
  /* margin-bottom: 3rem!important; */

}
.w_f{
  width: 100%;
  float: left;
}
.w_60{
  width:60% !important;
}
.w_53{
  width:53% !important;
}
.w_59{
  width:59% !important;
}
.w_70{
  width:70% !important;
}
.w_80{
  width:80% !important;
}
.auto_suggestion .form-control{
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
  height: 45px !important;
  border-radius: 0px !important;
  padding-left: 5px !important;
  padding-top: 11px;
  font-size: 14px;
 }
 .auto_suggestion .form-control input{
   color:#00529e !important;
 }
 .close_icon{
    left: 72%;
    position: absolute;
    top: 15%;
 }
 .business_label{
  color: #00529e !important;

 }
 .selm{
  margin: 0 0 0.8rem 0;
 }
 /* css for Reassign btn */
 /* .re_assign{
  right: 13rem !important; 
 }
 .cp_find{
   right: 1.5rem!important;
 }
 .reasign_btn{
   left: 17.3rem !important;
   bottom:2.7rem !important;
 } */

 
/* css for CSR */
/* .alert_label{
  color: #00529e;
  padding-top: -8rem;
  position: absolute;
  left: 54rem!important;
  top: -5rem!important;
}  */
/* .code_clr{
  color: #00529e;
  /* padding-left: 1rem!important; */
/* }  */
/* .codelabel_clr{
  color: #00529e;
  left:39rem!important;
  position: absolute;
  top: -5.8rem!important;
} */
.model_quickview{
  margin-top: 5% !important;
  /* margin-right:5% !important; */
}
/* select.indent {
  position: relative;
  right: 0.8rem!important;
} */

.time_filter{
  position: absolute !important;
  top: -1rem!important;
  right: 0rem!important;
  width: 24%;
}
select.timeindent{
  width:49.5%!important;
}
.status_Color{
  color: #00529e!important;
}
.card_heartheight{
  height: 250px!important;
}
.card_session{
  height:250px!important;
}
.heartbeat_clr{
  background-color:#72C93E!important;
}
.chargeSession_clr{
  background-color: #F8D045;
}
.status_group{
  background-color: #AA45F8;
}
.flashcode_icon{
 height: 10rem;
  height: 10rem;
}
.inventoryclose_icon{
  left: 90%;
  position: absolute;
  top: 15%;
}
.wrap-word{
  white-space: break-spaces;
  word-break: break-word;
}
.md-form.md-outline {
  position: relative;
  margin-top: -1.5rem;
  padding-left: 8rem;
  width: 125%;
}

.view-page-row1 label:first-child{
  width: 12%;
  text-align: left;
  font-weight: bold;
}
.view-page-row1 label{
  /* width: 48%; */
  text-align: left;
  padding-right: 14px;
  margin-bottom: 0px;
}
.edit_usericon{
  padding-left: 15px!important;
}

.tls_check{
  right:1rem;
  bottom:1rem;
}
.popupScroll{
  max-height: 64vh;
  overflow-y: auto;
}

.release-left{
  right:1rem!important;
}

.option { 
  background:white!important;
  color:#00529e !important;
}

.viewcpmodel_top{
  margin-top: 8%!important;
}
.selected_Vendor{
  padding-left: 5rem!important;
}
.config_key{
  left: auto !important;
  right: 0 !important;
}
.config_value{
  left: auto !important;
  right: 0 !important;
}
.page-table th{
  position: relative;
}
.model_active{
  margin-top: 20% !important;
}
.access_code{
  color:#00529e !important;
}
.no-wrap{
  white-space: nowrap;
}
.reset_timeout {
  margin: auto;
}
.timeout_counter{
text-align: center;
}
.pagination li a{
  position: relative;
  display: block;
  padding: 0rem 0rem; 
  margin-left: -1px;
  margin-left: 5px !important;
  border-radius: 43px;
  line-height: 1.25;
  color: rgb(0, 82, 158);
  background-color: #fff;
  border: 1px solid rgb(0, 82, 158);
  font-size: 12px;
  height: 23px;
  width: 23px;
  text-align: center;
  line-height: 1.7;
  }
  .pagination li.active a{
  border-radius: 100% !important;
  }
  .body-scroll{
    height: 300px;
    overflow-y: scroll;
  }


  /* setting popup */

  .settingOuterDiv .modal-backdrop.show{
    opacity: 0;
  }
  .settingOuterDiv .modal-backdrop .modal {
    position: absolute !important;
  }
  .settingOuterDiv .settingDiv{
    position:absolute;
    right:3%;
    top:38%;
  }
  .settingDiv label{
    width: 93%;
  }
  .settingOuterDiv .modal-header .close{
    display: block;
  }
  .activeLi{
    pointer-events: none;
  }
.nextBtn a{
  border: none !important;
  font-size: 12px !important;
  margin-top: -6px;
  margin-left: 8px !important;
}
.prevBtn a{
  border: none !important;
  font-size: 12px !important;
  margin-top: -6px;
  margin-right: 8px !important;
}
.calWidth{
  max-width: 29% !important;
}
.operationWidth{
  max-width: 20% !important;
}
.tag_callogs{
  width: 192px !important;
  margin-top: 0px !important;
}
.arrow_icon {
  cursor: pointer;
}
.w-74{
  width: 74% !important;
}
.w-c-icon{
  left: 68%;
  position: absolute;
  top: 15%;
}
.icon_fadeOut{
  cursor: default;
  opacity: 0.4;
  color: gray;
}
.icon_fadeOut:hover{
  opacity: 0.4;
}

/* Reservation calender graph styles */
#RBS-Scheduler-root th{
  background-color: #00529e !important;
  border-right: 0px !important;
  border-left: 0px !important;
  text-align: left !important;
}
#RBS-Scheduler-root th div{
  color: #fff !important;
}
.scheduler-view{
  border: 0px solid #e9e9e9 !important;
}
.scheduler-bg-table td{
  background-color: #eef4fa !important;
}
.ant-radio-button-wrapper, .ant-radio-group-outline{
  display: none !important; 
 }
.ant-radio-button-wrapper-checked{
  display: block;
}
.round-all{
  border-radius:0px !important;
}
.timeline-event > .event-item{
  border: 2px solid gray;
}
.rel_top_100{
  position: relative;
  top: 100px;
}
.ant-popover{
  z-index: 1050 !important;
}
.edit_link{
  text-decoration: underline;
}
.edit_link:hover{
  cursor: pointer;
}
.p-style td {
    border-top: 1px solid #dee2e6 !important;
}

.closinginput {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 0.5;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    border: 0;
    -webkit-appearance: none;
    appearance: none;
}

.closinginput:hover {
    color: #000 !important;
    text-decoration: none;
}

.apexcharts-tooltip #tooltipheader{
    background-color: #f4f8fd !important;
    padding: 5px;
}
.apexcharts-tooltip #tooltipbody{
    padding: 5px;
}
.apexcharts-tooltip #tooltipfooter{
    background-color: #f4f8fd !important;
    padding: 5px;
}
.apexcharts-tooltip #tooltipbody ::marker {
    color: blue;
    font-size: 1.5em;
  }
  
  #customization .customcheckbox .custom-control-label::before {
   
    left: 18.3rem !important;
}
#customization .customcheckbox .custom-control-label::after {
   
    left: 18.3rem !important;
}

#customization .custom-control-label::before {
   
    left: 15rem !important;
}
#customization .custom-control-label::after {
   
    left: 15rem !important;
}

#customization .menuicon_img{
    margin-right: 10px !important;
    vertical-align: top;
    padding-top: 4px;
}
#customization label{
    max-width: 87%;
    text-align: justifycenterstart;
    vertical-align: unset;
    padding-top: 0px;
}

@media (max-width:1281px ){
    #customization .customcheckbox .custom-control-label::before {
   
        left: 15.2rem !important;
    }
    #customization .customcheckbox .custom-control-label::after {
       
        left: 15.2rem !important;
    }
    #customization .customcheckbox-mid .custom-control-label::before {
   
        left: 14.9rem !important;
    }
    #customization .customcheckbox-mid .custom-control-label::after {
       
        left: 14.9rem !important;
    }

    #customization .custom-control-label::before {
   
        left: 14.3rem !important;
    }
    #customization .custom-control-label::after {
       
        left: 14.3rem !important;
    }
}
#customization #customize-subtags{
margin-left: 28px;
}
#customization #customizemaintags{
    margin-top:2rem;
}

#notification .custom-control-label::before {
   
    left: 17rem !important;
}
#notification .custom-control-label::after {
   
    left: 17rem !important;
}
#notification .custom-control {
    padding-left: 0px !important;
}
.bellicon{
    font-size: 1.5em !important;
}
.scrollbar {
    overflow-y: scroll;
  }
  .scrollbar-primary {
    scrollbar-color: #4285f4 #f5f5f5;
  }
  .scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285F4; }
  
  .scrollbar-danger {
    scrollbar-color: #ff3547 #f5f5f5;
  }
  
  .scrollbar-danger::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-danger::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-danger::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ff3547; }
  
  .scrollbar-warning {
    scrollbar-color: #ff8800 #f5f5f5;
  }
  
  .scrollbar-warning::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-warning::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #FF8800; }
  
  .scrollbar-success {
    scrollbar-color: #00c851 #f5f5f5;
  }
  
  .scrollbar-success::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-success::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-success::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #00C851; }
  
  .scrollbar-info {
    scrollbar-color: #33b5e5 #f5f5f5;
  }
  
  .scrollbar-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-info::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #33b5e5; }
  
  .scrollbar-default {
    scrollbar-color: #2bbbad #f5f5f5;
  }
  
  .scrollbar-default::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-default::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-default::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #2BBBAD; }
  
  .scrollbar-secondary {
    scrollbar-color: #aa66cc #f5f5f5;
  }
  
  .scrollbar-secondary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-secondary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-secondary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #aa66cc; }
  
.pagination li a {
    position: relative;
    display: block;
    padding: 1px 1px;
    margin-left: -1px;
    margin-left: 5px !important;
    border-radius: 43px;
    line-height: 1.25;
    color: rgb(0, 82, 158);
    background-color: #fff;
    border: 1px solid rgb(0, 82, 158);
    font-size: 8px;
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 2.7;
}

.pagination li button {
    position: relative;
    display: block;
    padding: 1px 1px;
    margin-left: -1px;
    margin-left: 5px !important;
    border-radius: 43px;
    line-height: 1.25;
    color: rgb(0, 82, 158);
    background-color: #fff;
    border: 1px solid rgb(0, 82, 158);
    font-size: 8px;
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 2.7;
}
body{
  background:#eef4fa !important;
  color: #00529e ;
}
/* dropdown css */
.dropdown-item{
  color: #00529e !important;
}
.dropdown .dropdown-menu .dropdown-item:hover{
  color: #1863a8;
  background-color: #fff;
}
.table th, .table td{
  border-top: 0px solid #dee2e6 !important;
}
/* end dropdown css */

/* breadcrumb css */
.dashboard .breadcrumbrow .page-title{
  font-size: 20px;
  line-height: 44px;
}
.dashboard .breadcrumbrow{
  background: #fff;
  height: 47px;
  margin-bottom: 17px;
  border: 1px solid #dbd8d8;
  border-radius: 0.1em;
  border-radius: 0.2em;
  margin-top: 0px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.breadcrumb{
  background: #fff !important;
}
 .breadcrumb_div{
  background: #fff;
  padding: 7px;
  border: 1px solid #dcd7d7;
  color: #00529e;
  position: relative;
}  
.breadcrumb_div div{
  position: absolute;
  right: 0;
  top: -6px;
}
.breadcrumb_div  a{
  color: #788195;
}

/* end breadcrumb css */

/* common css for table*/
  .table td {
    font-weight: 400;
    color: #00529e;
  }
  table.table a {
    margin: 0;
    color: #00529e;
  }
  table.table thead th {
    border: none !important;
    color: #00529e;
    background-color: #00529e;
    color: #fff;
    font-size: 12px;
    padding-left: 7px;
    
  }
  table.table thead th:first-child{
    border-top-left-radius: 10px;

  }
  table.table thead th:last-child{
    border-top-right-radius: 10px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
      background-color: #f4f8fd  !important;
  }
  table.table-hover tbody tr:hover{
    background-color: #93a7c0b0 !important;
  }
  tbody a{
    text-decoration: underline !important;
  }
/* end common css for table*/

/* =============================== commmon page css =====================================*/

/* page title for all "p" tag  */
  .page-outerdiv p{
    font-size: 21px;
    font-weight: 500;
    color: #00529e;
    border-bottom: 1px solid rgb(220, 217, 217);
    padding-bottom: 11px;
    
  }
  .form-check {
    position: relative;
    display: inline-block !important;
    padding-left: 1.25rem;
}
   .needs-validation, .form-title, .card-title, .form-check label, .log_title, .card-text, .md-form label, .custom-select, .modal-title, .form-control, .input-group-text, .input[type="file"]{
    color: #00529e !important;
    font-size: 13px !important;
  }

  .trans_lable, .nav-tabs .nav-link.active, .sel_lbl, .cal_label, .input_label, .modal-body, .accordion, .custom-control-label, .select_btn{
    color: #00529e;
  }
/* =============================== end commmon page css =====================================*/
/* common class */
.underline{
  text-decoration: underline;
}
.title_font{
  color: #00529e;
}
.white-skin .btn-primary {
  background-color: #1560a5 !important;
  color: #fff;
}
.btn-danger{
  background-color: #ce3829 !important;
  color: #fff;
}
.b-none{
  border: none !important;
}
.btn-primary{
  font-size: 13px;
}
.btn_cursor{
  cursor: pointer;
}
small.text-muted{
  color: #057ae5 !important;
}
.version_clr{
  color: transparent;
  position: fixed;
  right: 10px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green; */
  -webkit-text-fill-color: #00529e;
  -moz-text-fill-color: #00529e;
  /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
  /* transition: background-color 5000s ease-in-out 0s; */
}
/* end common class */

/* footer css */
 .fixed-sn footer {
  padding-left: 0 !important;
  background-color: #eef4fa!important;
  position: fixed;
  z-index: 10000;
  }
  footer.page-footer .footer-copyright {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    border-top: 1px solid #dcdcdc;
    background-color: inherit;
  }
  .footer_div{
    color: #00529e;
    font-size: 15px
  }
/* end footer css */

 .new_title{
  color: #fff;
  padding-left: 12px !important;
  padding-top: 9px !important;
  font-weight: 500;
}
.breadcrumb_div {
  background: #fff;
  padding: 7px;
  border: none;
  border-bottom: 1px solid #dcd7d7;
  color: #00529e;
  padding-left: 0px;
  padding-top: 0px;
} 
.fixed-sn main {
  padding-top: 5.3rem;
}
.in-flex{
  display: inline-flex;
}
.modal-footer {
  padding: 0.3rem !important;
}
.modal-header {
  padding: 0.5rem !important;
}
td{
  text-align: justify;
}
