body{
  background:#eef4fa !important;
  color: #00529e ;
}
/* dropdown css */
.dropdown-item{
  color: #00529e !important;
}
.dropdown .dropdown-menu .dropdown-item:hover{
  color: #1863a8;
  background-color: #fff;
}
.table th, .table td{
  border-top: 0px solid #dee2e6 !important;
}
/* end dropdown css */

/* breadcrumb css */
.dashboard .breadcrumbrow .page-title{
  font-size: 20px;
  line-height: 44px;
}
.dashboard .breadcrumbrow{
  background: #fff;
  height: 47px;
  margin-bottom: 17px;
  border: 1px solid #dbd8d8;
  border-radius: 0.1em;
  border-radius: 0.2em;
  margin-top: 0px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.breadcrumb{
  background: #fff !important;
}
 .breadcrumb_div{
  background: #fff;
  padding: 7px;
  border: 1px solid #dcd7d7;
  color: #00529e;
  position: relative;
}  
.breadcrumb_div div{
  position: absolute;
  right: 0;
  top: -6px;
}
.breadcrumb_div  a{
  color: #788195;
}

/* end breadcrumb css */

/* common css for table*/
  .table td {
    font-weight: 400;
    color: #00529e;
  }
  table.table a {
    margin: 0;
    color: #00529e;
  }
  table.table thead th {
    border: none !important;
    color: #00529e;
    background-color: #00529e;
    color: #fff;
    font-size: 12px;
    padding-left: 7px;
    
  }
  table.table thead th:first-child{
    border-top-left-radius: 10px;

  }
  table.table thead th:last-child{
    border-top-right-radius: 10px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
      background-color: #f4f8fd  !important;
  }
  table.table-hover tbody tr:hover{
    background-color: #93a7c0b0 !important;
  }
  tbody a{
    text-decoration: underline !important;
  }
/* end common css for table*/

/* =============================== commmon page css =====================================*/

/* page title for all "p" tag  */
  .page-outerdiv p{
    font-size: 21px;
    font-weight: 500;
    color: #00529e;
    border-bottom: 1px solid rgb(220, 217, 217);
    padding-bottom: 11px;
    
  }
  .form-check {
    position: relative;
    display: inline-block !important;
    padding-left: 1.25rem;
}
   .needs-validation, .form-title, .card-title, .form-check label, .log_title, .card-text, .md-form label, .custom-select, .modal-title, .form-control, .input-group-text, .input[type="file"]{
    color: #00529e !important;
    font-size: 13px !important;
  }

  .trans_lable, .nav-tabs .nav-link.active, .sel_lbl, .cal_label, .input_label, .modal-body, .accordion, .custom-control-label, .select_btn{
    color: #00529e;
  }
/* =============================== end commmon page css =====================================*/
/* common class */
.underline{
  text-decoration: underline;
}
.title_font{
  color: #00529e;
}
.white-skin .btn-primary {
  background-color: #1560a5 !important;
  color: #fff;
}
.btn-danger{
  background-color: #ce3829 !important;
  color: #fff;
}
.b-none{
  border: none !important;
}
.btn-primary{
  font-size: 13px;
}
.btn_cursor{
  cursor: pointer;
}
small.text-muted{
  color: #057ae5 !important;
}
.version_clr{
  color: transparent;
  position: fixed;
  right: 10px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green; */
  -webkit-text-fill-color: #00529e;
  -moz-text-fill-color: #00529e;
  /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
  /* transition: background-color 5000s ease-in-out 0s; */
}
/* end common class */

/* footer css */
 .fixed-sn footer {
  padding-left: 0 !important;
  background-color: #eef4fa!important;
  position: fixed;
  z-index: 10000;
  }
  footer.page-footer .footer-copyright {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    border-top: 1px solid #dcdcdc;
    background-color: inherit;
  }
  .footer_div{
    color: #00529e;
    font-size: 15px
  }
/* end footer css */

 .new_title{
  color: #fff;
  padding-left: 12px !important;
  padding-top: 9px !important;
  font-weight: 500;
}
.breadcrumb_div {
  background: #fff;
  padding: 7px;
  border: none;
  border-bottom: 1px solid #dcd7d7;
  color: #00529e;
  padding-left: 0px;
  padding-top: 0px;
} 
.fixed-sn main {
  padding-top: 5.3rem;
}
.in-flex{
  display: inline-flex;
}
.modal-footer {
  padding: 0.3rem !important;
}
.modal-header {
  padding: 0.5rem !important;
}
td{
  text-align: justify;
}